import styled, { StyledProps } from 'styled-components'
import { cvar, fsvar, fwvar, mediaQueries, shadowvar, svar } from 'styles'
import { IArticleCardProps } from '.'

export const Content = styled.article<
  StyledProps<Pick<IArticleCardProps, 'featured'>>
>`
  height: 100%;
  --display: flex;
  --gap: ${props => (props.featured ? `${svar('8')}` : `${svar('4')}`)};
  --flex-direction: column;

  display: var(--display);
  flex-direction: var(--flex-direction);
  justify-content: space-between;
  gap: var(--gap);

  ${mediaQueries.tabletLandUp} {
    --flex-direction: ${props => (props.featured ? 'row' : 'column')};
  }
`

export const DateReadTime = styled.div`
  display: flex;
  align-items: center;
  gap: ${svar('2')};
`

export const Desc = styled.p`
  ${mediaQueries.desktopUp} {
    font-size: ${fsvar('md')};
  }
`

export const DescWrapper = styled.div`
  --gap: ${svar('3')};

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  ${mediaQueries.tabletUp} {
    --padding-right: ${svar('10')};

    padding-right: var(--padding-right);
  }

  ${mediaQueries.tabletLandUp} {
    --padding-right: 0;
  }
`

export const InfoWrapper = styled.div<
  StyledProps<Pick<IArticleCardProps, 'featured'>>
>`
  --display: flex;
  --flex-direction: column;
  --padding: 0 16px 24px 16px;
  --gap: ${svar('5')};

  display: var(--display);
  flex-direction: var(--flex-direction);
  padding: var(--padding);
  gap: var(--gap);

  ${mediaQueries.tabletUp} {
    --padding: ${props =>
      props.featured && `0 ${svar('7')} ${svar('7')} ${svar('7')}`};
  }

  ${mediaQueries.tabletLandUp} {
    --padding: ${props =>
      props.featured && `${svar('4')} ${svar('4')} ${svar('4')} ${svar('4')}`};
  }

  ${mediaQueries.desktopUp} {
    --gap: ${props => props.featured && `${svar('10')}`};
  }
`

export const Title = styled.h3`
  --font-size: ${fsvar('lg')};

  font-size: var(--font-size);
  color: ${cvar('primary')};
  font-weight: ${fwvar('bold')};

  ${mediaQueries.tabletUp} {
    --font-size: ${fsvar('lg')};
  }

  ${mediaQueries.desktopUp} {
    --font-size: ${fsvar('2xl')};
  }
`

export const Wrapper = styled.article<
  StyledProps<Pick<IArticleCardProps, 'featured' | 'idx'>>
>`
  border-radius: 5px;
  background-color: ${cvar('white')};
  box-shadow: ${shadowvar('white-medium')};

  ${mediaQueries.tabletUp} {
    width: ${props => !props.featured && '46%'};
  }

  ${mediaQueries.tabletLandUp} {
    --grid-column: span 5;
    grid-column: var(--grid-column);
    width: auto;
  }

  ${mediaQueries.desktopUp} {
    --grid-column: ${props =>
      props.idx && props.idx > 1 ? 'span 5' : 'span 3'};
  }
`
