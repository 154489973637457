import { Divider } from 'blog-components/Divider'
import { Link as GatsbyLink } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { CoverFragment } from 'types'
import * as S from './styles'

export interface IArticleCardProps {
  title: string
  desc: string
  timeToRead: number
  publishedAt: string
  cover: CoverFragment
  featured?: boolean
  slug: string
  idx?: number
}

export default function ArticleCard({
  title,
  desc,
  publishedAt,
  timeToRead,
  cover,
  featured,
  slug,
  idx
}: IArticleCardProps) {
  const imgData = cover.image?.childImageSharp?.gatsbyImageData
  const alt = cover?.alt_text || ''
  const image = getImage(imgData)

  const imageStyles = {
    borderRadius: featured ? '5px 0 0 5px' : '5px 5px 0 0'
  }

  return (
    <S.Wrapper idx={idx} featured={featured}>
      <GatsbyLink to={`/blog${slug}`}>
        <S.Content featured={featured}>
          <GatsbyImage imgStyle={imageStyles} image={image!} alt={alt} />
          <S.InfoWrapper featured={featured}>
            <S.Title>{title}</S.Title>
            <S.DescWrapper>
              <S.Desc>{desc}</S.Desc>
              <S.DateReadTime>
                <time>{publishedAt}</time>
                <Divider />
                <p>{timeToRead} min read</p>
              </S.DateReadTime>
            </S.DescWrapper>
          </S.InfoWrapper>
        </S.Content>
      </GatsbyLink>
    </S.Wrapper>
  )
}
