import Headline from 'components/Headline'
import SEO from 'components/SEO'
import React from 'react'
import styled, { css } from 'styled-components'
import { cvar, fwvar, mediaQueries, svar } from 'styles'
import Container from './Container'

interface IBlogLayoutProps {
  children: React.ReactNode
}

export default function BlogLayout({ children }: IBlogLayoutProps) {
  return (
    <>
      <SEO
        title="Blog"
        description="Get the latest news, blog posts, and special offers in your inbox.

"
      />
      <Container styles={containerStyles}>
        <Headlines>
          <Headline>Blog</Headline>
          <SubHeadline>
            Get the latest news, blog posts, and special offers in your inbox.
          </SubHeadline>
        </Headlines>
        {children}
      </Container>
    </>
  )
}

const containerStyles = () => css`
  --gap: ${svar('20')};

  ${mediaQueries.desktopUp} {
    --gap: ${svar('30')};
  }
`

const Headlines = styled.div`
  --gap: ${svar('4')};

  display: flex;
  flex-direction: column;
  gap: var(--gap);
`

const SubHeadline = styled.h2`
  font-weight: ${fwvar('normal')};
  color: ${cvar('text-charcoal')};
`
