import Articles from 'blog-components/Articles'
import { graphql } from 'gatsby'
import BlogLayout from 'layouts/BlogLayout'
import React from 'react'
import { AllArticleQuery, IArticleCardInfo } from 'types'

interface IBlogHomePageProps {
  data: AllArticleQuery
}

export default function BlogHomePage({ data }: IBlogHomePageProps) {
  return (
    <BlogLayout>
      <Articles articles={data.articlesInfo.nodes as IArticleCardInfo[]} />
    </BlogLayout>
  )
}

export const query = graphql`
  query AllArticle {
    articlesInfo: allMarkdownRemark {
      nodes {
        frontmatter {
          title
          description
          publishedAt
          featured
          cover {
            ...Cover
          }
        }
        timeToRead
        fields {
          slug
        }
      }
    }
  }
`
