import ArticleCard from 'blog-components/ArticleCard'
import React from 'react'
import styled from 'styled-components'
import { mediaQueries, svar } from 'styles'
import { IArticleCardInfo } from 'types'

interface IArticlesProps {
  articles: IArticleCardInfo[]
}

export default function Articles({ articles }: IArticlesProps) {
  const featuredArticles = articles.filter(
    article => article.frontmatter.featured
  )

  const otherArticles = articles.filter(
    article => !article.frontmatter.featured
  )

  const renderFeaturedArticles = () =>
    featuredArticles.map(article => (
      <ArticleCard
        key={article.frontmatter.title}
        title={article.frontmatter.title}
        desc={article.frontmatter.description}
        publishedAt={article.frontmatter.publishedAt}
        timeToRead={article.timeToRead}
        cover={article.frontmatter.cover}
        slug={article.fields.slug}
        featured={article.frontmatter.featured}
      />
    ))

  const renderOtherArticles = () =>
    otherArticles.map((article, idx) => (
      <ArticleCard
        key={article.frontmatter.title}
        title={article.frontmatter.title}
        desc={article.frontmatter.description}
        publishedAt={article.frontmatter.publishedAt}
        timeToRead={article.timeToRead}
        cover={article.frontmatter.cover}
        slug={article.fields.slug}
        idx={idx}
      />
    ))

  return (
    <Wrapper>
      {renderFeaturedArticles()}
      <ArticlesGrid>
        {renderOtherArticles()}
        {/* <Form /> */}
      </ArticlesGrid>
    </Wrapper>
  )
}

const ArticlesGrid = styled.div`
  --display: flex;
  --flex-direction: column;
  --row-gap: ${svar('20')};

  display: var(--display);
  flex-direction: var(--flex-direction);
  row-gap: var(--row-gap);

  ${mediaQueries.tabletUp} {
    --flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ${mediaQueries.tabletLandUp} {
    --display: grid;
    --column-gap: ${svar('6')};
    --grid-template-columns: repeat(10, 1fr);

    grid-template-columns: var(--grid-template-columns);
    column-gap: var(--column-gap);
  }
`

// export const Form = styled.div`
//   background-color: red;
//   grid-row-start: 1;
//   grid-column-start: 7;
//   grid-column-end: 11;
// `

const Wrapper = styled.div`
  --display: flex;
  --flex-direction: column;
  --gap: ${svar('20')};

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
`
